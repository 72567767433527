import React from "react";
import { useContext } from "react";
import { AppContext } from "./AppContext";
import "../style/Footer.css";



export default function Footer() {
  const { classSwitch, photosArr } = useContext(AppContext);
  return (
    <div className={!classSwitch || typeof photosArr !== "string" ? "invisible" : "copyright" }>
      &copy; 2022 Copyright by&nbsp;
      <a
        className="twitter-link"
        target="_blank"
        rel="noopener noreferrer"
        href="https://twitter.com/RobertWickowsk3"
      >
        Robert Więckowski
      </a>
      . Driven by the need to know and explore.
    </div>
  );
}
