import axios from "axios";

export default axios.create({
  baseURL: "https://api.unsplash.com",
  headers: {
    Authorization: "Client-ID _fPwi2-b3CAwndL1KtAR17oNyuNqtt2CPALtWTQ1O0o",
  },
});


// import axios from "axios";

// export default axios.create({
//   baseURL: "https://api.unsplash.com",
//   headers: {
//     Authorization: "Client-ID xN-kAk-l1izHCRbZKD0bxfwXcfK33huDOYAiu6MBjZo",
//   },
// })