import React from "react";
import { useContext } from "react";
import { AppContext } from "./AppContext";
import "../style/Search.css";


export default function NoImagesMsg() {

  const {
    classSwitch,
    closeSuggestion,   
    photosArr,
    inputPointer,
  } = useContext(AppContext);

  return (
    <div>
      <div
        style={{
          animation:
            typeof photosArr !== "string" && classSwitch ? "fade 3s" : "",
        }}
        className={`${typeof photosArr !== "string" ? "no-images" : ""} ${
          typeof photosArr !== "string" && !classSwitch
            ? "no-images--start"
            : ""
        } `}
      >
        {typeof photosArr !== "string" && classSwitch
          ? "There Are No Images To Display"
          : ""}
      </div>
      <div className="center-message">
        <div
          style={{
            animation:
              typeof photosArr !== "string" && !classSwitch && inputPointer
                ? "fade--start 3s"
                : "",
          }}
          className={`${
            typeof photosArr !== "string" ? "no-images--start" : ""
          } ${
            typeof photosArr !== "string" && !classSwitch
              ? "no-images--start"
              : ""
          } `}
        >
          {typeof photosArr !== "string" &&
          !classSwitch &&
          inputPointer &&
          closeSuggestion
            ? "Ups! There Are Not Images To Display"
            : ""}
        </div>
      </div>

      <div
        style={{
          animation:
            typeof photosArr !== "string" && !classSwitch && inputPointer
              ? "fade--start 3s"
              : "",
        }}
        className={`${
          typeof photosArr !== "string"
            ? "no-images--start message-second-line"
            : ""
        } ${
          typeof photosArr !== "string" && !classSwitch
            ? "no-images--start message-second-line"
            : ""
        } `}
      >
        {typeof photosArr !== "string" &&
        !classSwitch &&
        inputPointer &&
        closeSuggestion
          ? "Try Another Word To Show Them"
          : ""}
      </div>
    </div>
  );
}
