import React from "react";
import "../style/PhotoList.css";
import PhotoCard from "./PhotoCard";

export default function PhotoList(props) {
  const photos = props.photos.map((photo) => {
    return <PhotoCard key={photo.id} photo={photo} />;
  });
  return (
    <section>
      {/* <div className="errors">{props.errors}</div> */}
      <div className="photo-container">
        <div className="photo-list">{photos}</div>
      </div>
    </section>
  );
}
