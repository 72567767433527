import React from "react";
import { useContext } from "react";
import { AppContext } from "./AppContext";
import "../style/Modal.css";
import { FaTimes } from "react-icons/fa";

export default function Modal() {
  const {
    modal,
    setModal,
    photoToDisplay,
    photoDescription,
    photoDate,
    photoBy,
    userLocation,
    photoAuthor,
  } = useContext(AppContext);

  const date = new Date(photoDate);
  const options = {
    year: "numeric",
    month: "long",
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  return (
    <>
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
            <img
              className="modal-photo animate-opacity"
              src={photoToDisplay}
              alt={photoDescription}
            />
            <div className="modal-footer">
              <div className="modal-author">
                <img
                  className="modal-author--photo"
                  src={photoAuthor}
                  alt={photoAuthor}
                />
                <ul className="modal-author--description">
                  <li>
                    {photoBy
                      ? ` ${photoBy}`
                      : "There Is No Data About The Author Of The Photo"}
                  </li>
                </ul>
              </div>
              <div className="modal-data">
                <ul className="modal-author--description">
                  <li className="modal-location">
                    {userLocation
                      ? userLocation
                          .split(" ")
                          .map(
                            (portion) =>
                              portion.charAt(0).toUpperCase() + portion.slice(1)
                          )
                          .join(" ")
                      : "No Location Data"}
                  </li>
                  <li>
                    {photoDate
                      ? new Intl.DateTimeFormat("en-US", options).format(date)
                      : "- No Date Data Available"}
                  </li>
                </ul>
              </div>
            </div>

            <button className="close-modal" onClick={toggleModal}>
              <FaTimes />
            </button>
          </div>
        </div>
      )}
    </>
  );
}
