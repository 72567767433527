import React from "react";
import { useRef, useEffect, useState } from "react";
import { useContext } from "react";
import { AppContext } from "./AppContext";

export default function PhotoCard(props) {
  const {
    modal,
    setModal,
    setPhotoToDisplay, 
    setPhotoDate,
    setPhotoBy,
    PhotoBy,
    setUserLocation,
    setPhotoAuthor,
    classSwitch,
    setClassSwitch,
    setCloseSuggestion,
    setPhotosArr,
  } = useContext(AppContext);
  const [stateArea, setAreaState] = useState(0);

  useEffect(() => {   
    setCloseSuggestion(true);
    props.photo ? setClassSwitch(true) : setClassSwitch(false);
    if (!classSwitch) {document.body.style.overflowY = "scroll"}
  }, []);

  const toggleModal = () => {
    setModal(!modal);
    setPhotoToDisplay(urls.regular);
    setPhotoDate(created_at);
    setPhotoBy(user.name);
    setUserLocation(user.location);
    setPhotoAuthor(user.profile_image.medium);
  };
  const { alt_description, urls, created_at, user } = props.photo;
  const photoRef = useRef();
  useEffect(() => {
    photoRef.current.addEventListener("load", setStretch);
  }, []);

  function setStretch() {
    if (photoRef.current.clientHeight === null) return console.log("error");
    let height = photoRef.current.clientHeight;

    const stretch = Math.ceil(height / 7 + 2);
    setAreaState({ stretch });
  }

  useEffect(() => {
    setPhotosArr(props.photo.id);
  }, []);

  return (
    <div style={{ gridRowEnd: `span ${stateArea.stretch}` }}>
      <img
        onClick={toggleModal}
        ref={photoRef}
        src={urls.regular}
        alt={PhotoBy}
      />
      <div className="tags">
        <span className="tag">
          {alt_description
            ? alt_description
                .split(" ")
                .slice(0, 18)
                .map(
                  (portion) =>
                    portion.charAt(0).toUpperCase() + portion.slice(1)
                )
                .join(" ")
            : "There Is No Description Available For This Image"}
        </span>
      </div>
    </div>
  );
}
