import React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { AppProvider } from "./AppContext";
import { ErrorBoundary } from "./ErrorBoundery";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Search from "./Search";
import unsplash from "../api/unsplash";
import PhotoList from "./PhotoList";
import Footer from "./Footer";
import Modal from "./Modal";
import ScrollUp from "./ScrollUp";
import words from "./data/words.json";

export default function App() {
  const [errors, setErrors] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [numPage, setNumPage] = useState(1);

  let suggestion = words[0].words;

  async function handleSearchSubmit(name) {
    const response = await unsplash
      .get("/search/photos", {
        params: { query: name, per_page: 21, page: numPage },
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log(`Error: ${err.message}`);
        }
      });

    try {
      setPhotos(response.data.results);
    } catch {
      console.log(
        `Message: there are no images available related to this name`
      );
    }
  }

  return (
  
      <div>
        <AppProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Via Images</title>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
            <meta
              name="description"
              content="Website for anyone interested in exploring the world via images."
            />
          </Helmet>
          <ErrorBoundary>
            <Search
              suggestions={suggestion}
              onSubmit={handleSearchSubmit}
              numPage={numPage}
              setNumPage={setNumPage}
            />
            <ScrollUp />
            <PhotoList errors={errors} photos={photos} />
            <Footer />
            <Modal />
          </ErrorBoundary>
        </AppProvider>
      </div>

  );
}
