import React, { useState } from "react";
import "../style/Search.css";
import "../style/Autocomplete.css";
import { useContext } from "react";
import { AppContext } from "./AppContext";
import { FaSistrix } from "react-icons/fa";
import { BiLeftArrow } from "react-icons/bi";
import { BiRightArrow } from "react-icons/bi";
import { BsBoxArrowDownRight } from "react-icons/bs";
import NoImagesMsg from "./NoImagesMsg";

export default function Search(props) {
  const {
    classSwitch,
    pagination,
    setPagination,
    closeSuggestion,
    setCloseSuggestion, 
    setPhotosArr,
    setInputPointer,
  } = useContext(AppContext);
  const [storage, setStorage] = useState();
  const [name, setName] = useState([]);
  const [noSuggestion, setNoSuggestion] = useState(true);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0);
  const [displaySuggestions, setDisplaySuggestions] = useState(false);

  const handleInputChange = (evt) => {
    setCloseSuggestion(false);
    const currentInput = evt.target.value;

    let toSuggestion = [];
    if (currentInput.length >= 3) {
      setNoSuggestion(false);
      toSuggestion = props.suggestions.filter(
        (suggestion) =>
          suggestion.toLowerCase().indexOf(currentInput.toLowerCase()) > -1
      );
    } else {
      setNoSuggestion(true);
    }
    setName(evt.target.value);
    setFilteredSuggestions(toSuggestion);
    setSelectedSuggestionIndex(0);
    setDisplaySuggestions(true);
  };

  const onClick = (evt) => {
    setFilteredSuggestions([]);
    setName(evt.target.innerText);
    setSelectedSuggestionIndex(0);
    setDisplaySuggestions(false);
  };

  const handleKeys = (evt) => {
    if (evt.keyCode === 13) {
      setName(filteredSuggestions[selectedSuggestionIndex]);
      setSelectedSuggestionIndex(0);
      setDisplaySuggestions(false);
    } else if (evt.keyCode === 38) {
      if (selectedSuggestionIndex === 0) {
        return;
      }

      setSelectedSuggestionIndex(selectedSuggestionIndex - 1);
    } else if (evt.keyCode === 40) {
      if (selectedSuggestionIndex - 1 === filteredSuggestions.length) {
        return;
      }

      setSelectedSuggestionIndex(selectedSuggestionIndex + 1);
    }
  };

  const SuggestionsList = () => {
    return filteredSuggestions.length ? (
      <ul
        style={{ color: classSwitch ? "#214a5e" : "#f7f9fb" }}
        className="suggestions"
      >
        {filteredSuggestions.map((suggestion, index) => {
          let className;

          if (index === selectedSuggestionIndex) {
            className = "suggestion-active";
          }

          return (
            <li className={className} key={suggestion} onClick={onClick}>
              {suggestion}
            </li>
          );
        })}
      </ul>
    ) : (
      <div
        className="no-suggestions"
        style={{ color: classSwitch ? "#f7f9fb" : "#f7f9fb" }}
      >
        <span aria-label="no suggestion">
          {!noSuggestion ? "There is no suggestions" : ""}
        </span>
      </div>
    );
  };

  function handleFormSubmit(evt) {
    name !== storage && props.setNumPage(1);
    setPhotosArr([]);
    setInputPointer(true);
    if (evt) {
      evt.preventDefault();
      props.onSubmit(name);
      setStorage(name);
      props.onSubmit(name);
      setCloseSuggestion(true);
    } else {
      props.onSubmit(storage);
      setPhotosArr([]);
    }
  }

  function handleInputClear(evt) {
    evt.preventDefault();
    setName([]);
    setCloseSuggestion(true)
  }
  function handleMinusPage() {
    props.numPage > 1 && props.setNumPage(props.numPage - 1);
    props.numPage === 2 ? setPagination(false) : setPagination(true);
    props.numPage > 1 && handleFormSubmit();
  }
  function handlePlusPage() {
    props.setNumPage(props.numPage + 1);
    setPagination(true);
    handleFormSubmit();
  }
  console.log(name)
  return (
    <div className="search">
      <div
        className={
          classSwitch ? "search-container--reduce" : "search-container"
        }
      >
        <form
          className={
            classSwitch ? "search-form--two form" : "search-form--one form"
          }
          onSubmit={handleFormSubmit}
        >
          <link rel="next" href="https://api.unsplash.com/topics?page=1" />

          <div className="label-container">
            <div className="control-container">
              <div className="label-text-container">
                <label
                  className={
                    classSwitch ? "search-label-reversed" : "search-label"
                  }
                >
                  Explore the World
                  <span
                    className={
                      classSwitch
                        ? "search-label--viaimages"
                        : "search-label--viaimages-reversed"
                    }
                  >
                    &nbsp;Via Images
                  </span>
                </label>
              </div>
              <div className="search-line">
                <div className="search-bar">
                  <div className={!pagination ? "no-active" : ""}>
                    <button
                      className={!classSwitch ? "no-active" : "btn-prev"}
                      type="text"
                      onClick={handleMinusPage}
                      title="Previous"
                    >
                      <span className="prev">
                        <BiLeftArrow />
                      </span>
                    </button>
                  </div>

                  <div className="search-wrapper">
                    <>
                      <input
                        style={{ color: "#687864" }}
                        className="search-input"
                        type="text"
                        value={name}
                        onKeyDown={handleKeys}
                        onChange={handleInputChange}
                        placeholder="Search photos"
                      />
                      {!closeSuggestion && displaySuggestions && name && (
                        <SuggestionsList />
                      )}
                    </>
                  </div>

                  <div>
                    <button
                      className="clear-btn"
                      type="click"
                      aria-label="clear"
                      onClick={handleInputClear}
                      title="Clear"
                    >
                      <div className={name === undefined || name.length !== 0 ? "search-magnifier" : "search-clear--inactive"}>
                        <BsBoxArrowDownRight />
                      </div>
                    </button>
                  </div>

                  <div>
                    <button
                      className="search-btn"
                      type="submit"
                      aria-label="search"
                    >
                      <div className={name === undefined || name.length !== 0 ? "search-magnifier" : "search-clear--inactive"}>
                        <FaSistrix />
                      </div>
                    </button>
                  </div>

                  <div className={!classSwitch ? "no-active" : ""}>
                    <button
                      className="btn-next"
                      type="text"
                      onClick={handlePlusPage}
                      title="Next"
                    >
                      <span className="next">
                        <BiRightArrow />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>          
            <NoImagesMsg/>
          </div>
        </form>
      </div>
    </div>
  );
}
