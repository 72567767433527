import { createContext, useState } from "react";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [modal, setModal] = useState(false);
  const [photoToDisplay, setPhotoToDisplay] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [photoDate, setPhotoDate] = useState(null);
  const [photoBy, setPhotoBy] = useState(null);
  const [photoAuthor, setPhotoAuthor] = useState(null);
  const [classSwitch, setClassSwitch] = useState(false);
  const [pagination, setPagination] = useState(true);
  const [closeSuggestion, setCloseSuggestion] = useState(false);
  const [photosArr, setPhotosArr] = useState([]);
  const [inputPointer, setInputPointer] = useState(false);

  return (
    <AppContext.Provider
      value={{
        modal,
        setModal,
        photoToDisplay,
        setPhotoToDisplay,     
        photoDate,
        setPhotoDate,
        photoBy,
        setPhotoBy,
        userLocation,
        setUserLocation,
        photoAuthor,
        setPhotoAuthor,
        classSwitch,
        setClassSwitch,
        pagination,
        setPagination,
        closeSuggestion,
        setCloseSuggestion,
        photosArr,
        setPhotosArr,
        inputPointer,
        setInputPointer,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
